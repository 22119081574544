import React, { useState } from 'react'
import oko from "../../../assets/images/oko.svg";
import { formatLostTime } from '../../../utils/formatLostTime';

function TimeCell({ time, label, ...props }) {
    const [isExpanded, setIsExpanded] = useState(false);
    
    return (
        <>
        <div
            className="time --client copy-element"
            onClick={() => {
                setIsExpanded(!isExpanded);
            }}
        >
            {isExpanded ? (
                <>
                    {new Date(time).toLocaleString("uk-UA").split(".").join("/")}
                    <br></br>
                </>
            ) :
                formatLostTime(new Date() - new Date(time))
            }
            <img сlassName="oko" width={12} style={{ marginLeft: "5px" }} src={oko} alt="" />
        </div>
        </>
    )
}

export default TimeCell