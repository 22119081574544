import { makeAutoObservable } from "mobx";

export default class ExchangeStore {
  constructor() {
    this._exchanges = [];
    makeAutoObservable(this);

    const storedExchanges = localStorage.getItem("activeExchanges");
    if (storedExchanges) {
      this._exchanges = JSON.parse(storedExchanges);
    } else {
      this._exchanges = [];
      this._updateLocalStorage();
    }
  }

  _updateLocalStorage() {
    if (this._exchanges !== null) {
      localStorage.setItem("activeExchanges", JSON.stringify(this._exchanges));
    }
  }

  get exchanges() {
    return this._exchanges;
  }

  setExchanges(exchanges) { 
    this._exchanges = exchanges;
    this._updateLocalStorage();
  }

  get totalCount() {
    return this._exchanges.length;
  }

  addItem(item) {
    this._exchanges.push(item);
    this._updateLocalStorage();
  }

  removeItem(_id) {
    let idx = this._exchanges.findIndex((item) => item._id === _id);
    this._exchanges.splice(idx, 1);
    this._updateLocalStorage();
  }

  removeAllItems() {
    this._exchanges = [];
    this._updateLocalStorage([]);
  }
}
