import React, { useEffect, useRef } from "react";
import arrow_left from "../../assets/images/arrowadminleft.svg";
import arrow_right from "../../assets/images/arrowadminright.svg";
import PairCell from "./TableCells/PairCell";
import TimeCell from "./TableCells/TimeCell";
import '../../assets/css/custom-scrollbar.css'; // Импортируйте стили

const PaginatedTable = ({ items, currentPage, totalPages, setCurrentPage, setAlertIsOpen, headers }) => {
  const scrollableRef = useRef(null); // Создаем реф для scrollable

  const tableCellDict = (key, props, label) => {
    const dict = {
      'pair': <PairCell {...props} />,
      'time': props && <TimeCell time={props} label={label}/>,
      'start_time': props && <TimeCell time={props} label={label}/>,
      'created_at': props && <TimeCell time={props} label={label}/>
    }
    return dict[key]
  }

  const paginatorRender = () => {
    const buttons = [];
    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${currentPage === i ? "active_page" : ""}`} onClick={() => setCurrentPage(i)}>
            {i}
          </div>
        );
      }
    } else {
      for (let i = 1; i <= 5; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${currentPage === i ? "active_page" : ""}`} onClick={() => setCurrentPage(i)}>
            {i}
          </div>
        );
      }
      buttons.push(
        <div key={0} className={`page-button margin`}>
          ...
        </div>
      );
      for (let i = totalPages - 4; i <= totalPages; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${currentPage === i ? "active_page" : ""}`} onClick={() => setCurrentPage(i)}>
            {i}
          </div>
        );
      }
    }

    return buttons;
  };

  const renderEmptyRows = (rows) => {
    const res_rows = [];
    for (let i = rows; i < 10; i++) {
      res_rows.push(
        <tr className="content_table" key={i}>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
        </tr>
      );
    }
    return res_rows;
  };

  useEffect(() => {
    const horzScrollbarDetect = () => {
      const scrollable = scrollableRef.current;
      const innerDiv = scrollable.querySelector('div');
      
      if (innerDiv.offsetWidth < innerDiv.scrollWidth) {
        scrollable.classList.add('is-scrollable');
      } else {
        scrollable.classList.remove('is-scrollable');
      }
    };

    horzScrollbarDetect();
    window.addEventListener('resize', horzScrollbarDetect);

    return () => {
      window.removeEventListener('resize', horzScrollbarDetect);
    };
  }, []);

  return (
    <div className="content_block row">
      <div className="scrollable" ref={scrollableRef}>
        <div>
          <table className={`client-table ${items.length < 1 && 'empty'}`}>
            <thead>
              <tr className="th_names">
                {headers.map(header => (
                  <th scope="col" key={header.key} style={{ width: header.width, maxWidth: header.width, minWidth: `${100 / headers.length}%`}} className={header.className}>{header.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items.length ? items
                ?.slice()
                ?.reverse()
                ?.map((item, index) => {
                  return (
                    <tr className="content_table" key={index}>
                      {headers.map((header) => {
                        let value;
                        if (header.key === 'pair') {
                          value = { item, fixed: item.fixed };
                        } else {
                          value = header.valueFormatter ? header.valueFormatter(item[header.key]) : item[header.key];
                        }

                        return (
                          <td data-label={header.label} key={header.key} style={{ width: header.width, maxWidth: header.width, minWidth: header.width }} className="padding">
                            {tableCellDict(header.key, value, header.label) || value}
                          </td>
                        );
                      })}
                    </tr>
                  );
                }) : <tr className="empty-table-placeholder"><td colSpan={headers.length}>Nothing found</td></tr>}
              {renderEmptyRows(items?.results?.length)}
            </tbody>
          </table>
        </div>
        <div className="slidepages" style={{ userSelect: "none" }}>
          <div
            className="arrowleft margin"
            onClick={() => {
              items?.next && setCurrentPage((prev) => prev - 1);
            }}
          >
            <img src={arrow_left} alt="" />
          </div>
          {paginatorRender()}
          <div
            className="arrowright margin"
            onClick={() => {
              items?.previous && setCurrentPage((prev) => prev + 1);
            }}
          >
            <img src={arrow_right} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaginatedTable;
