import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Context } from "../..";

function PrivateRoute({ children }) {
  const { admin, client } = useContext(Context);
  const location = useLocation();
    const tokenKey = location.pathname.includes("/admin") ? 'admin-token' : 'token';
  const hasTokens = document.cookie.includes(`${tokenKey}=`) || document.cookie.includes('refreshToken=');
  if (location.pathname.includes("/admin") && (!admin.isAuth || !hasTokens)) {
    return <Navigate to="/admin/login" />;
  }
  if (location.pathname.includes("/client") && (!client.isAuth || !hasTokens)) {
    return <Navigate to="/" />;
  }

  if (!hasTokens) {
    return <Navigate to="/" />;
  }

  return children;
}

export default PrivateRoute;
