import React, { useState, useEffect } from "react";
import Close from "../../assets/images/close.svg";
import Electro from "../../assets/images/electro.svg"
import "react-datepicker/dist/react-datepicker.css";
import { AdminService } from "../../http/services/AdminService";
import arrow_left from "../../assets/images/arrowadminleft.svg";
import arrow_right from "../../assets/images/arrowadminright.svg";

const Pairs = ({ options }) => {

  //Pairs TABLE
  const [exchangesList, setExchangesList] = useState(null);
  const [exchCurrPage, setExchCurrPage] = useState(1);
  const [exchTotalPages, setExchTotalPages] = useState(null);
  const [filterHandle, setFilterHandle] = useState(false);

  const [filterKey, setFilterKey] = useState("");

  const toggleIsMicroService = ({id, value}) => {
    AdminService.switchIsMicroservice({id, value}).then(() => {
      AdminService.getPairs({ page: exchCurrPage,  }).then(res => setExchangesList(res))
    })

  }

  const paginatorRender = () => {
    const buttons = []
    if (exchTotalPages > 10) {
      for (let i = 1; i <= 3; i++) {
        buttons.push(
          <div
            key={i}
            className={`page-button margin ${exchCurrPage === i ? 'active_page' : ''}`}
            onClick={() => setExchCurrPage(i)}
          >
            {i}
          </div>
        )
      }
      buttons.push(
        <div key="ellipsis" className="page-button margin">
          ...
        </div>
      )
      for (let i = exchTotalPages - 2; i <= exchTotalPages; i++) {
        buttons.push(
          <div
            key={i}
            className={`page-button margin ${exchCurrPage === i ? 'active_page' : ''}`}
            onClick={() => setExchCurrPage(i)}
          >
            {i}
          </div>
        )
      }
    } else {
      for (let i = 1; i <= exchTotalPages; i++) {
        buttons.push(
          <div
            key={i}
            className={`page-button margin ${exchCurrPage === i ? 'active_page' : ''}`}
            onClick={() => setExchCurrPage(i)}
          >
            {i}
          </div>
        )
      }
    }
    return buttons
  }

  const renderEmptyRows = (rows) => {
    const res_rows = [];
    for (let i = rows; i < 10; i++) {
      res_rows.push(
        <tr className="content_table">
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
        </tr>
      );
    }
    return res_rows;
  };

  useEffect(() => {
    console.log(exchCurrPage)
    AdminService.getPairs({ page: exchCurrPage, search: filterKey }).then(res => {
      const newTotalPages = Math.ceil(res.count / 10);
        setExchTotalPages(newTotalPages);
        if (exchCurrPage >= newTotalPages) {
          setExchCurrPage(1);
        }
      setExchangesList(res)
    })
  }, [exchCurrPage, filterKey])

  return (
    <div className="right_block">
      <ul className="navbar">
      <li style={{ position: "relative" }}>
          <input
            className="Filterbykey"
            placeholder="Search by ID"
            value={filterKey}
            type="text"
            onChange={(e) => {
              setFilterKey(e.target.value);
            }}
          />
          {filterKey ? <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer" }} onClick={() => setFilterKey("")} alt="" /> : null}
        </li>
        <button id="admin-pnl-btn" onClick={() => setFilterHandle(!filterHandle)}>
          <img src={Electro} alt="" />
        </button>
      </ul>
      <div className="content_block">
        <div className="content_block">
          <table className="table">
            <tr className="th_names">
              <th className="radius">ID</th>
              <th>Coin</th>
              <th>Memo</th>
              <th>Network</th>
              <th>Microservice</th>
            </tr>

            {exchangesList?.results
              ?.slice()
              ?.map((item) => {
                return (
                  <tr className="content_table">
                    <td>
                      <div className="url_block" style={{ alignItems: "center" }}>
                        <div
                          className="Url copy-element"
                          onClick={() => {
                            navigator.clipboard.writeText(item?.id);
                            // setAlertIsOpen(true);
                          }}
                        >
                          {item.id}
                        </div>
                        <div className="market">{ }</div>
                      </div>
                    </td>
                    <td>
                      <div className="url_block" style={{ alignItems: "center" }}>
                        <div
                          className="Url copy-element"
                          onClick={() => {
                            navigator.clipboard.writeText(item?.coin.title);
                            // setAlertIsOpen(true);
                          }}
                        >
                          {item.coin.token}
                        </div>
                        <div className="market">{ }</div>
                      </div>
                    </td>
                    <td className="padding">
                      <div style={{ width: '26px', margin: 'auto' }}>

                        <input type="checkbox" readOnly checked={item.coin.is_memo}></input>
                      </div>
                    </td>
                    <td className="padding">
                      <div className="part_profit">
                        {item.network.title}
                      </div>
                    </td>
                    <td className="padding">
                      <div style={{ width: '26px', margin: 'auto' }}>

                        <input type="checkbox" onClick={() => toggleIsMicroService({id: item.id, value: !item.is_microservice})} checked={item.is_microservice}></input>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {renderEmptyRows(exchangesList?.results?.length)}
          </table>
          <div className="slidepages" style={{ userSelect: "none" }}>
            <div
              className="arrowleft margin"
              onClick={() => {
                exchangesList?.previous && setExchCurrPage((prev) => prev - 1);
              }}
            >
              <img src={arrow_left} alt="" />
            </div>
            {paginatorRender()}
            <div
              className="arrowright margin"
              onClick={() => {
                exchangesList?.next && setExchCurrPage((prev) => prev + 1);
              }}
            >
              <img src={arrow_right} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Pairs;