import React from 'react'
import {ReactComponent as ArrowRight} from "../../../assets/images/arrowadminright.svg";

function PairCell({ item, label }) {
  return (
    <>
      <div className="pairs --client">
        <div className="leftpair">
          <div className="flex-block">
            {item?.token1_amount} {item?.token1?.toUpperCase()}
          </div>
        </div>
        <ArrowRight stroke='black' />
        <div className="rightpair">
          <div className="flex-block">
            {item?.token2_amount} {item?.token2?.toUpperCase()}
          </div>
        </div>
      </div>
    </>
  )
}

export default PairCell