import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import { ReactComponent as Logo } from '../../assets/images/logo.svg'
import { UserService } from "../../http/services/UserService";

export default function ChangePassword({ show, onClose }) {
  const modalRef = useRef(null);
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [show, onClose]);

  if (!show) return null;

  const onSubmit = (data) => {
    const payload = {
      old_password: data.oldPassword,
      new_password: data.newPassword,
    }
    UserService.changePassword(payload).then(() => {
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
        onClose();
      }, 3000);
    }).catch(error => {
      console.error('Failed to change password:', error);
    });
  };

  const handleNotificationClick = () => {
    setShowNotification(false);
    onClose();
  };

  return createPortal(
    <div className="modal-bg">
      <div className="login-modal" ref={modalRef}>
        <div className="login-modal__header">
          <h2 className="login-modal__title">Change Password</h2>
        </div>

        <div className="login-modal__form-container">
          <form onSubmit={handleSubmit(onSubmit)} className="login-form">
            <div className="login-form__field">
              <label htmlFor="oldPassword" className="login-form__label">
                Current Password
              </label>
              <div className="login-form__input-wrapper">
                <input
                  id="oldPassword"
                  {...register("oldPassword", { 
                    required: "Current password is required"
                  })}
                  type="password"
                  className={`login-form__input ${errors.oldPassword ? 'validation_failed' : ''}`}
                />
              </div>
              {errors.oldPassword && <span className="login-form__error">{errors.oldPassword.message}</span>}
            </div>

            <div className="login-form__field">
              <label htmlFor="newPassword" className="login-form__label">
                New Password
              </label>
              <div className="login-form__input-wrapper">
                <input
                  id="newPassword"
                  {...register("newPassword", { 
                    required: "New password is required",
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters"
                    },
                  })}
                  type="password"
                  className={`login-form__input ${errors.newPassword ? 'validation_failed' : ''}`}
                />
              </div>
              {errors.newPassword && <span className="login-form__error">{errors.newPassword.message}</span>}
            </div>

            <div className="login-form__field">
              <label htmlFor="confirmNewPassword" className="login-form__label">
                Confirm New Password
              </label>
              <div className="login-form__input-wrapper">
                <input
                  id="confirmNewPassword"
                  {...register("confirmNewPassword", { 
                    required: "Please confirm your new password",
                    validate: (val) => {
                      if (watch('newPassword') !== val) {
                        return "Your passwords do not match";
                      }
                    }
                  })}
                  type="password"
                  className={`login-form__input ${errors.confirmNewPassword ? 'validation_failed' : ''}`}
                />
              </div>
              {errors.confirmNewPassword && <span className="login-form__error">{errors.confirmNewPassword.message}</span>}
            </div>

            <div className="login-form__submit">
              <button
                type="submit"
                className="login-form__button"
              >
                Change Password
              </button>
            </div>
          </form>
        </div>
      </div>
      {showNotification && (
        <div className="success-notification" onClick={handleNotificationClick}>
          Password changed successfully!
        </div>
      )}
    </div>
  , document.body);
}
