import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/logo.svg";
import fb from "../assets/images/fb.svg"
import tw from "../assets/images/x.svg"
import inst from "../assets/images/inst.svg"
import inn from "../assets/images/in.svg"
import tel from "../assets/images/tel.svg"
import redit from "../assets/images/redit.svg"
import ds from "../assets/images/ds.svg"


const Footer = () => {
  const location = useLocation("");
  const [footer_cn, setFooter_cn] = useState()
  useEffect(() => {
    if (location.pathname === '/contacts') {
      setFooter_cn('footer_contacts')
    } else if (location.pathname === '/terms') {
      setFooter_cn('footer_back')
    } else if (location.pathname === '/privacy') {
      setFooter_cn('footer_privacypolicy')
    } else if (location.pathname === '/exchange') {
      setFooter_cn('exanges_footer')
    } else {
      setFooter_cn('')
    }
  }, [location])
  return (
    <footer className={footer_cn} style={{userSelect: "none"}}>
      <div className="layout_container">
        <div className="footer_flex_block">
          <div className="footer_left_block">
            <img src={Logo} alt="logo" />
            <div className="footer_sosial_block">
              {/* <Link to="https://google.com" target="_blank" rel="noopener noreferrer">
                <img src={fb} alt="sosial" />
              </Link> */}
              <Link to="https://x.com/swaponix_ex" target="_blank" rel="noopener noreferrer">
                <img src={tw} alt="sosial" />
              </Link>
              <Link to="https://www.instagram.com/swaponix" target="_blank" rel="noopener noreferrer">
                <img src={inst} alt="sosial" />
              </Link>
              <Link to="https://www.linkedin.com/company/swaponix/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                <img src={inn} alt="sosial" />
              </Link>
              <Link to="https://t.me/swaponixswap" target="_blank" rel="noopener noreferrer">
                <img src={tel} alt="sosial" />
              </Link>
              <Link to="https://www.reddit.com/u/Swaponix/s/sk0sxUgZKB" target="_blank" rel="noopener noreferrer">
                <img src={redit} alt="sosial" />
              </Link>
              {/* <Link to="#" target="_blank" rel="noopener noreferrer">
                <img src={ds} alt="sosial" />
              </Link> */}
            </div>
          </div>
          <div className="footer_right_block">
            <div className="flex_maintext">
              <div className="flex_block">
                <div className="first_text">
                  <p>
                    {" "}
                    <Link to="#">Company</Link>
                  </p>
                  <div className="text_block">
                    <NavLink to="about">About</NavLink>
                    <NavLink to="contacts">Contacts</NavLink>
                  </div>
                </div>
                <div className="second_text">
                  <p>
                    {" "}
                    <NavLink to="#">Support</NavLink>
                  </p>
                  <div className="text_block">
                    <NavLink to="howtouse">How to use</NavLink>
                  </div>
                </div>
              </div>
              <div className="text">
                <span>Swaponix</span> is ​​progressing and becoming faster with each new swap you make!
              </div>
              <div className="flex_block">
                <div className="Terms_of_Use">
                  <NavLink to="terms">Terms of Use</NavLink>
                </div>
                <div className="Privacy_Policy">
                  <NavLink to="privacy">Privacy Policy</NavLink>
                </div>
              </div>
              {/* <div className="author">
                <span>Developers: </span>
                <span className="author_text">N.Ivakhiv Team & A.Repei</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
