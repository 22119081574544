import React from 'react';

export default function Tooltip({ message, children }) {
  return (
    <div className="tooltip">
      {children}
      <div className="tooltip__message">
        <div className="tooltip__arrow"></div>
        {message}
      </div>
    </div>
  );
}
