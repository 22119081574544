import React, { useState, useEffect } from "react";
import { host } from "../../http";
import Close from "../../assets/images/close.svg";
import Alert from "./Alert";
import { AdminService } from "../../http/services/AdminService";

const UpdatePartnerPayout = ({onClose, item, statuses }) => {
  const [alertIsOpen, setAlertIsOpen] = useState(false)
  const [status, setStatus] = useState(item?.status || null);
  const [coinAmount, setCoinAmount] = useState(null);
  const [txid, setTxid] = useState(null);
  const [changesSaved, setChangesSaved] = useState(false);
    console.log(item)
  useEffect(() => {
    if (changesSaved === true) {
      setTimeout(() => {
        setChangesSaved(false);
        setStatus(null);
        setCoinAmount(null);
        setTxid(null);
        onClose()
      }, 1000)
    }
  }, [changesSaved])

  useEffect(() => {
    setStatus(item?.status)
    setCoinAmount(item?.deposit_id);
    setTxid(item?.withdraw_id);
  }, [statuses, item])

  const saveChanges = (id) => {
    let status = 'pending';
    if (txid && (coinAmount || coinAmount === 0)) status = 'completed' 
    AdminService.updateParnterPayout({id, coin_amount: coinAmount, txid, status }).then(res => {
        console.log("Here")
    })
  }

  return (
    <section className="modal">
      <article className="modal-content">
        <div className="exit-icon">
          <div className="div-2">Update</div>
          <img
            src={Close}
            width={30}
            onClick={() => {
              onClose();
            }}
            alt=""
          />
        </div>
        <div className="div">
          
          <div style={{ display: "flex", flexDirection: 'column', gap: "20px" }}>
            <div className="opened">
              <div className="div-7">
                <div className="css-gebwvy-control">
                  <div className="css-ValueContainer">
                    <label style={{padding: "10px 0 0 0", color: "#fff"}}>Coin amount</label>
                    <div className="valueInpUpdate">
                      <input type="number" value={coinAmount} placeholder="Coin amount" onChange={(e) => setCoinAmount(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opened">
              <div className="div-7">
                <div className="css-gebwvy-control">
                  <div className="css-ValueContainer">
                    <label style={{padding: "10px 0 0 0", color: "#fff"}}>TxID</label>
                    <div className="valueInpUpdate">
                      <input type="text" value={txid} placeholder="TxID" onChange={(e) => setTxid(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
          
          {changesSaved ? (
            <span style={{ textAlign: "center", fontSize: "1rem", fontWeight: 600, fontFamily: "Montserrat", margin: "25px 0 10px" }}>Changes saved!</span>
          ) : (
            <button className="div-10" onClick={() => saveChanges(item?.uid)}>Save</button>
          )}
        </div>
      </article>
      <style jsx>{`
        .modal {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .modal-content {
          border: 1px solid #fff;
          width: 750px;
          background: linear-gradient(91deg, #7927ff 0%, #8b1ea6 100%);
          border: none;
          border-radius: 1.5rem;
        }
        .exit-icon {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          padding: 15px 20px 0 25px;
          align-items: center;
          font-size: 20px;
          margin-bottom: 20px;
        }
        .modal-title {
          color: #6821c3;
          font-weight: 700;
          font-size: 30px;
          line-height: 28px;
        }
        .modal-image img {
          width: 8rem;
        }
        .modalText {
          text-align: justify;
        }
        .modal-button button {
          border: 1px solid #6821c3;
          padding: 6px;
          width: 8rem;
          background-color: #6821c3;
          color: white;
          border-radius: 7px;
        }
        .div {
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          font-size: 11px;
          color: #fff;
          font-weight: 600;
          padding: 0 25px 20px;
        }
        .div-2 {
          font: 700 18px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
          color: #fff;
        }
        .div-3 {
          font-family: Montserrat, sans-serif;
          border-radius: 0.6rem;
          border-color: rgba(255, 255, 255, 1);
          background-color: rgba(255, 255, 255, 0.1);
          border-style: solid;
          border-width: 1px;
          margin-top: 9px;
          font-weight: 500;
          white-space: nowrap;
          justify-content: center;
          padding: 2px 5px;
        }
        .opened {
          width: 100%;
          padding: 0;
          margin: 0;
          margin-top: 7px;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 0.6rem;
        }
        .hidden {
          display: none;
        }
        .order_select_items {
          display: block;
        }
        .div-7 {
          font-family: Montserrat, sans-serif;
          border-radius: 0.6rem;
          white-space: nowrap;
          justify-content: center;
          padding: 2px 5px;
          padding-bottom: 20px;
        }
        .order_select_items {
          display: flex;
          justify-content: center;
        }
        .order_select_items span {
          width: 100%;
          font-family: Montserrat, sans-serif;
          font-size: 1rem;
          border-radius: 0.6rem;
          background-color: rgba(0, 0, 0, 0.6);
          padding: 15px 0;
          margin: 10px;
          text-align: center;
          cursor: pointer;
        }
        .div-8 {
          border-radius: 7px;
          backdrop-filter: blur(2px);
          background-color: rgba(108, 108, 108, 0.7);
          display: flex;
          margin-top: 7px;
          gap: 20px;
          white-space: nowrap;
          justify-content: space-between;
          padding: 12px 12px 12px 6px;
        }
        .div-9 {
          font-family: Montserrat, sans-serif;
        }
        .img {
          aspect-ratio: 1.43;
          object-fit: auto;
          object-position: center;
          width: 10px;
          fill: #fff;
          align-self: start;
        }
        .div-10 {
          font-family: Montserrat, sans-serif;
          border-radius: 0.5rem;
          border: none;
          background-color: #27ae60;
          align-self: end;
          margin-top: 6px;
          white-space: nowrap;
          padding: 10px 25px;
          font-size: 1rem;
          color: #fff;
          font-weight: 600;
          margin: 20px 0 0 0;
          cursor: pointer;
        }
      `}</style>
      {alertIsOpen ? (
        <Alert
          onClose={() => {
            setAlertIsOpen(false);
          }}
          text="Saved!"
        />
      ) : null}
    </section>
  );
};

export default UpdatePartnerPayout;
