import React, { useContext, useEffect, useState } from "react";
import Logo from "../assets/images/logo.svg"
import Eye from "../assets/images/oko.svg"
import { host } from "../http";
import { jwtDecode } from 'jwt-decode';

import { Context } from "../index";
import { observer } from 'mobx-react-lite';
import { useNavigate } from "react-router-dom";

const Login = observer(() => {
  const { admin } = useContext(Context);
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("")
  const [hidePass, setHidePass] = useState(true)

  const signIn = async (e) => {
    e.preventDefault();
    try {
      const response = await host.post("/user/api/auth/authorization/", { username, password, code, is_admin: true});
      if (response.data.access) {
        document.cookie = `admin-token=${response.data.access}; path=/`;
        document.cookie = `refreshToken=${response.data.refresh}; path=/`;
        const data = jwtDecode(response.data.access);
        admin.setUser({id: data.id});
        admin.setIsAuth(true);
        navigate('/admin');
      }
    } catch (e) {
      e.response.data.code ? 
        alert(e.response.data.code)
        :
        alert(e.response.data.error)
    }
  };

  useEffect(() => {
    if (admin.isAuth) {
      navigate('/admin/swaps');
    }
  }, [admin.isAuth]);

  return (
    <main className="login">
      <div className="admin-flex">
        <div className="admin-card">
          <img loading="lazy" src={Logo} className="adm-img" alt="" />
          <input
            id="username"
            type="text"
            placeholder="Login"
            className="login_inp"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <div className="copy_inp">
            <input
              id="password"
              type={hidePass ? "password" : "text"}
              placeholder="Password"
              className="password-inp"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <img loading="lazy" src={Eye} className="adm-img-2" onClick={() => setHidePass(!hidePass)} alt="" />
          </div>
          <input
            type="text"
            placeholder="Code"
            className="code_inp"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <button className="login_btn" onClick={(e) => signIn(e)}>Login</button>
        </div>
      </div>
    </main>
  );
});

export default Login;
