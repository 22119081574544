import { useEffect, useState } from 'react';

const useModal = () => {
	const [isShowing, setIsShowing] = useState(false);

	function toggle() {
		setIsShowing(!isShowing);
	}

	useEffect(() => {
		if (isShowing) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [isShowing]);

	return [
		isShowing,
		toggle
	];
}

export default useModal;
