import React, { useContext, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/logo.svg";
import UnitedKingdomFlag from "../assets/images/United-Kingdom 1.svg";
import UAFlag from "../assets/images/ua.svg";
import MobileLogo from "../assets/images/swaponix.svg";
import ArrowYellow from "../assets/images/arrowyellow.svg";
import useModal from "../hooks/useModal";
import LoginModal from "./modals/ClientAuthModal";
// import Logo from "../../assets/images/client_logo.svg";
import LogoString from "../assets/images/logo_string.svg";
import { ReactComponent as DashboardIcon } from "../assets/icons/nav-icons/dashboard.svg";
import { ReactComponent as HistoryIcon } from "../assets/icons/nav-icons/history.svg";
import { ReactComponent as SettingsIcon } from "../assets/icons/nav-icons/settings.svg";
import { ReactComponent as BurgerMenuIcon } from "../assets/icons/nav-icons/burger.svg";
import { ReactComponent as ChangePasswordIcon } from "../assets/icons/nav-icons/change-pass.svg";
import { ReactComponent as CloseIcon } from "../assets/images/close.svg";
import { ReactComponent as LogoutIcon } from "../assets/icons/nav-icons/logout.svg";
import Tooltip from "./ui/Tooltip";

const Header = () => {
  const location = useLocation();
  const [mobileToggle, setMobileToggle] = useState(false);
  const [isShowingModal, toggleModal] = useModal();
  const [isSidebarShown, setIsSidebarShown] = useState(false);
  const [isPartnersDropdownOpen, setPartnersDropdownOpen] = useState(false);

  return (
    <>
      <header>
        <div className="header_centerblock">
          <div className="nav">
            <div className="logo">
              <NavLink to="/">
                <img src={Logo} alt="logo" />
              </NavLink>
            </div>
            <ul>
              <li>
                <NavLink to="about">About</NavLink>
              </li>
              <li>
                <NavLink to="howtouse">How to use</NavLink>
              </li>
              <li>
                <NavLink to="contacts">Contacts</NavLink>
              </li>
              <li className="hover_li">
                <div
                  className={`tooltip ${isPartnersDropdownOpen && "show"}`}
                  onFocus={() => setPartnersDropdownOpen(true)}
                  onBlur={() => setPartnersDropdownOpen(false)}
                >
                  Partners <img src={ArrowYellow} alt="" />
                  <div className="tooltip__message dropdown-content">
                    <div className="tooltip__arrow --left-align"></div>
                    <div onClick={toggleModal}>Affiliate Program</div>
                    <a href="https://api.swaponix.io/swagger/integration/">
                      API Documentation
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <Tooltip message={"Soon"}>
                  <span className="text-gold-gradient">Public nodes</span>
                </Tooltip>
              </li>
              <div className="lan">
                <img
                  className="United-Kingdom"
                  src={UnitedKingdomFlag}
                  alt="ENG"
                />
                EN
                {/* <div className="lan_change"><img className="" style={{width: "25px"}} src={UAFlag} alt="UA"/>UA</div> */}
              </div>
              <LoginModal show={isShowingModal} onClose={toggleModal} />
              <div role="button" onClick={toggleModal} className="login-block">
                Sign in
              </div>
            </ul>
          </div>
          <div style={{ position: "relative" }} className="nav_mobile">
            <div className="logo">
              <NavLink to="/">
                <img src={MobileLogo} alt="" />
              </NavLink>
              <button
                onClick={() => setIsSidebarShown(!isSidebarShown)}
                style={{
                  position: "absolute",
                  right: "20px",
                  top: 0,
                  bottom: 0,
                  margin: "auto",
                  zIndex: 8,
                }}
                className={`navigation-toggle-btn --yellow-svg ${
                  isSidebarShown ? "open" : ""
                }`}
              >
                {isSidebarShown ? <CloseIcon /> : <BurgerMenuIcon />}
              </button>
            </div>
            <div className={`navigation-mob ${isSidebarShown ? "open" : ""}`}>
              <div style={{ maxWidth: "unset" }} className="logo-container">
                <Link to="/client/dashboard">
                  <img width={103} height={84} src={Logo} alt="" />
                </Link>
                <button
                  onClick={() => setIsSidebarShown(!isSidebarShown)}
                  className={`navigation-toggle-btn --yellow-svg ${
                    isSidebarShown ? "open" : ""
                  }`}
                >
                  {isSidebarShown ? <CloseIcon /> : <BurgerMenuIcon />}
                </button>
              </div>
              <div className="links-container">
                <ul>
                  <ul
                    id="list1"
                    style={{
                      display: "block",
                      paddingLeft: "15px",
                      fontWeight: 700,
                    }}
                  >
                    <li
                      onClick={() => {
                        setIsSidebarShown(false);
                      }}
                      className={
                        location.pathname === "/client/dashboard"
                          ? "active-item"
                          : ""
                      }
                    >
                      <NavLink to="about">About</NavLink>
                    </li>
                    <li
                      onClick={() => {
                        setIsSidebarShown(false);
                      }}
                      className={
                        location.pathname === "/client/history"
                          ? "active-item"
                          : ""
                      }
                    >
                      <NavLink to="howtouse">How to use</NavLink>
                    </li>
                    <li
                      onClick={() => {
                        setIsSidebarShown(false);
                      }}
                      className={
                        location.pathname === "/client/settings"
                          ? "active-item"
                          : ""
                      }
                    >
                      <div
                        className={`tooltip ${
                          isPartnersDropdownOpen && "show"
                        }`}
                        onFocus={() => setPartnersDropdownOpen(true)}
                        onBlur={() => setPartnersDropdownOpen(false)}
                      >
                        Partners <img src={ArrowYellow} alt="" />
                        <div className="tooltip__message dropdown-content">
                          <div className="tooltip__arrow --left-align"></div>
                          <div onClick={toggleModal}>Affiliate Program</div>
                          <a href="https://api.swaponix.io/swagger/integration/">
                            API Documentation
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </ul>
                <div className="navigation__bottom">
                  <ul
                    id="list1"
                    style={{
                      // display: "block",
                      paddingLeft: "15px",
                      fontWeight: 700,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                    }}
                  >
                    <li
                      onClick={() => {
                        // setIsSidebarShown(false);
                      }}
                      className={
                        location.pathname === "/client/dashboard"
                          ? "active-item"
                          : ""
                      }
                    >
                      {/* <Link to="#"> */}
                        <Tooltip message={"Soon"}>
                          <span className="text-gold-gradient">
                            Public nodes
                          </span>
                        </Tooltip>
                      {/* </Link> */}
                    </li>
                    <li
                      onClick={() => {
                        setIsSidebarShown(false);
                      }}
                      className={
                        location.pathname === "/client/history"
                          ? "active-item"
                          : ""
                      }
                    >
                      <NavLink to="contacts">Contacts</NavLink>
                    </li>
                    <li
                      onClick={() => {
                        setIsSidebarShown(false);
                      }}
                      className={
                        location.pathname === "/client/settings"
                          ? "active-item"
                          : ""
                      }
                    >
                      <div
                        role="button"
                        onClick={toggleModal}
                        className="login-block --mob"
                      >
                        Sign in
                      </div>
                    </li>
                  </ul>
                  {/* <button onClick={() => {}} className="header-button --logout">
                <LogoutIcon />
                Log out
              </button>
              <button onClick={toggleModal} className="header-button --change-password">
                <ChangePasswordIcon />
                Change Password
              </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
