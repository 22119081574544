import { authHost, host } from "../index";

const BASE_PATH = process.env.REACT_APP_API_URL + '/user/api';

export const UserService = {
    login: async (data) => {
        const payload = {
            is_admin: false,
            username: data.username,
            password: data.password,
        }
        return await host.post(`${BASE_PATH}/auth/authorization/`, payload).then(res => {
            return res.data
        })
    },
    register: async (data) => {
        const payload = {
            is_admin: false,
            username: data.username,
            password: data.password,
            referral: data.referral
        }
        return await host.post(`${BASE_PATH}/auth/registration/`, payload).then(res => {
            return res.data
        })},
    logout: () => host.post(`${BASE_PATH}/auth/logout/`, {}),
    refresh: () => host.post(`${BASE_PATH}/auth/refresh/`, {}),

    getStats: async () => {
        return await authHost.get(`${BASE_PATH}/user/statistics/`).then(res => res.data)},
    changePassword: async (data) => {
        return await authHost.post(`${BASE_PATH}/auth/change-password/`, data).then(res => res.data)
    },
    getToken: async () => {
        return await authHost.get(`${BASE_PATH}/user/token/`).then(res => res.data)
    },

    getRef: async () => {
        return await authHost.get(`${BASE_PATH}/user/link/`).then(res => res.data)
    },

    getOrderStatus: async (id) => {
        return await host.get(`/exchange/api/${id}/`).then(res => {
            return res.data
        })
    } 
}