import React from "react";
import Close from "../../assets/images/close.svg";
import galkaOK from "../../assets/images/galkaOK.svg";

const Alert = ({onClose, text = null }) => {
  const displayText = text ?? "Copied!";
  setTimeout(() => (displayText === "Copied!" || displayText === "Saved!") && onClose(), 1000);
  return (
    <section className="modal">
      <article className="modal-content">
        <div className="exit-icon">
          {(displayText !== "Copied!" && displayText !== "Saved!") ? 
          <img
          src={Close}
          width={30}
          onClick={() => {
            onClose();
          }}
          alt=""
            />
            :
            null
          }
        </div>
        <div className="div">
          {(displayText === "Copied!" || displayText === "Saved!") ? (
            <>
            <img src={galkaOK} width={50} alt="" />
              <h1 className="modalText">{displayText}</h1>
            </>
          ) : (
            <div className="div-8">
              <div className="css-gebwvy-control">
                  <div className="css-singleValue">
                   {displayText.split("_").map((line) => (<p style={{ margin:"10px 0 10px 5px", fontWeight: 500, fontSize: "1rem" }}>{line}</p>))}
                  </div>
              </div>
            </div>
          )}
        </div>
      </article>
      <style jsx>{`
        .modal {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          // background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .modal-content {
          border: none;
          min-width: 250px;
          backdrop-filter: blur(2px);
          background-color: rgba(108, 108, 108, 0.7);
          border: none;
          border-radius: 1.5rem;
        }
        .exit-icon {
          cursor: pointer;
          display: flex;
          justify-content: end;
          padding: 15px 20px 0 25px;
          align-items: center;
          font-size: 20px;
        }
        .modal-title {
          color: #6821c3;
          font-weight: 700;
          font-size: 30px;
          line-height: 28px;
        }
        .modal-image img {
          width: 8rem;
        }
        .modalText {
          font-size: 2rem;
          text-align: justify;
        }
        .modal-button button {
          border: 1px solid #6821c3;
          padding: 6px;
          width: 8rem;
          background-color: #6821c3;
          color: white;
          border-radius: 7px;
        }
        .div {
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 11px;
          color: #fff;
          font-weight: 600;
          padding: 0 25px 20px;
        }
        .div-2 {
          font: 700 18px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
          color: #fff;
        }
        .div-3 {
          font-family: Montserrat, sans-serif;
          border-radius: 0.6rem;
          border-color: rgba(255, 255, 255, 1);
          background-color: rgba(255, 255, 255, 0.1);
          border-style: solid;
          border-width: 1px;
          margin-top: 9px;
          font-weight: 500;
          white-space: nowrap;
          justify-content: center;
          padding: 2px 5px;
        }
        .opened {
          width: 100%;
          padding: 0;
          margin: 0;
          margin-top: 7px;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 0.6rem;
        }
        .hidden {
          display: none;
        }
        .order_select_items {
          display: block;
        }
        .div-7 {
          font-family: Montserrat, sans-serif;
          border-radius: 0.6rem;
          white-space: nowrap;
          justify-content: center;
          padding: 2px 5px;
        }
        .order_select_items {
          display: flex;
          justify-content: center;
        }
        .order_select_items span {
          width: 100%;
          font-family: Montserrat, sans-serif;
          font-size: 0.8rem;
          border-radius: 0.6rem;
          background-color: rgba(0, 0, 0, 0.6);
          padding: 15px 0;
          margin: 10px;
          text-align: center;
          cursor: pointer;
        }
        .div-8 {
          border-radius: 7px;
          width: 100%;
          backdrop-filter: blur(2px);
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          margin-top: 7px;
          gap: 20px;
          white-space: nowrap;
          justify-content: space-between;
          padding: 12px 12px 12px 6px;
        }
        .div-9 {
          font-family: Montserrat, sans-serif;
        }
        .img {
          aspect-ratio: 1.43;
          object-fit: auto;
          object-position: center;
          width: 10px;
          fill: #fff;
          align-self: start;
        }
        .div-10 {
          font-family: Montserrat, sans-serif;
          border-radius: 0.5rem;
          border: none;
          background-color: #27ae60;
          align-self: center;
          margin-top: 6px;
          white-space: nowrap;
          justify-content: center;
          padding: 15px 50px;
          font-size: 1rem;
          color: #fff;
          font-weight: 600;
          margin: 20px 0 0 0;
          cursor: pointer;
        }
      `}</style>
    </section>
  );
};

export default Alert;
