import React from "react";
import '../../assets/css/all.css'
import { Outlet, useLocation } from "react-router-dom";
import Header from '../Header';
import Footer from '../Footer';

const Layout = () => {
  const location = useLocation()
  return (
    <div id="wrapper">
      {(location.pathname!=='/admin' && location.pathname!=='/select' && location.pathname!=='/summary' ) ? <Header/>:null}
      <Outlet />
      {(location.pathname!=='/admin' && location.pathname!=='/select' && location.pathname!=='/summary' ) ? <Footer />:null}
    </div>
  );
};

export default Layout;
