import axios from 'axios';

const host = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const authHost = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const adminHost = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const authAdminInterceptor = config => {
  config.headers.authorization = `Bearer ${document.cookie.split('; ').find(row => row.startsWith('admin-token=')).split('=')[1]}`;
  return config;
}

const authClientInterceptor = config => {
  config.headers.authorization = `Bearer ${document.cookie.split('; ').find(row => row.startsWith('token=')).split('=')[1]}`;
  return config;
}

authHost.interceptors.request.use(authClientInterceptor);
adminHost.interceptors.request.use(authAdminInterceptor);

const refreshInterceptor = error => {
  if ((error.response.status === 401 || error.response.status === 403) && error.config.url !== '/user/api/jwt/refresh/') {
    const refreshToken = document.cookie.split('; ').find(row => row.startsWith('refreshToken=')).split('=')[1];
    return authHost.post('/user/api/jwt/refresh/', {
      refresh: refreshToken
    })
      .then(response => {
        document.cookie = `token=${response.data.access}; path=/; secure; samesite=strict`;
        error.config.headers.authorization = `Bearer ${response.data.access}`;
        return authHost(error.config);
      })
      .catch(error => {
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "admin-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        throw error;
      });
  } 
  throw error;
}

const refreshAdminInterceptor = error => {
  if ((error.response.status === 401 || error.response.status === 403) && error.config.url !== '/user/api/jwt/refresh/') {
    const refreshToken = document.cookie.split('; ').find(row => row.startsWith('refreshToken=')).split('=')[1];
    return authHost.post('/user/api/jwt/refresh/', {
      refresh: refreshToken
    })
      .then(response => {
        document.cookie = `admin-token=${response.data.access}; path=/; secure; samesite=strict`;
        error.config.headers.authorization = `Bearer ${response.data.access}`;
        return authHost(error.config);
      })
      .catch(error => {
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "admin-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        throw error;
      });
  } 
  throw error;
}

authHost.interceptors.response.use(null, refreshInterceptor);
adminHost.interceptors.response.use(null, refreshAdminInterceptor)

export { host, authHost, adminHost };