import React, { useContext, useState } from "react";
import icons from "../../assets/icons/coin_map.json";
import Electro from "../../assets/images/electro.svg";
import Close from "../../assets/images/close.svg";
import "react-datepicker/dist/react-datepicker.css";
import { Context } from "../..";

const TokensS = () => {
  const { admin: { options }} = useContext(Context)
  const [tokenF, setTokenF] = useState("");

  return (
    <div className="right_block">
      <ul className="navbar">
        <li className="hover ">
          <input
            type="text"
            className="select"
            placeholder="Currency"
            value={tokenF}
            onChange={(e) => setTokenF(e.target.value)}
            style={{ background: "none", border: "none", padding: "0", margin: "0", width: "100%", height: "20px" }}
          />{" "}
          { 
            tokenF ? 
              <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer"}} onClick={() => setTokenF("")} alt="" />
              :
              null
          }
        </li>
        <button id="admin-pnl-btn" style={{marginLeft: "5px"}} onClick={() => {
          setTokenF("")
        }}>
          <img src={Electro} alt="" />
        </button>
      </ul>
      <div className="content_block">
        {options
          ?.filter((el) => (el?.token?.includes(tokenF) || el?.label?.includes(tokenF)))
          ?.map((opt) => {
          const crypto_icon = icons[opt?.token.toUpperCase()] ? icons[opt?.token.toUpperCase()] : icons["BTC"];
          return (
            <div className="div">
              <div className="div-2">
                <img src={crypto_icon} width={20} style={{ borderRadius: "5px", marginRight: "5px" }} alt={opt?.token} />
                {opt.title + " (" + opt.networks.map(e => e.title) + ")"}
              </div>
              <div className="div-3">
                <div className="div-4">{opt?.networks?.length}</div>
                <div className="div-5">Deposit: {opt?.networks[0]?.title}</div>
              </div>
              <div className="div-6">
                <div className="div-7">{opt?.networks?.length}</div>
                <div className="div-8">Withdraw: {opt?.networks[0]?.title}</div>
              </div>
              <div className="div-9">
                <label class="switch">
                  <input type="checkbox" />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          );
        })}
        <style jsx>{`
          .div {
            border-radius: 10px;
            background-color: rgba(54, 54, 54, 0.6);
            display: flex;
            justify-content: space-between;
            padding: 10px 7px;
            margin: 5px 0 0 0;
          }
          @media (max-width: 991px) {
            .div {
              flex-wrap: wrap;
              padding-left: 20px;
            }
          }
          .div-2 {
            display: flex;
            color: #fff;
            margin: auto 0;
            font: 500 14px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
            align-items: center;
            width: calc(100% / 4);
          }
          .div-3 {
            display: flex;
            margin-top: 4px;
            gap: 4px;
            color: #fff;
            font-weight: 500;
            white-space: nowrap;
            width: calc(100% / 4);
          }
          @media (max-width: 991px) {
            .div-3 {
              white-space: initial;
            }
          }
          .div-4 {
            background-color: #494949;
            justify-content: center;
            padding: 6px 5px;
            font: 12px Montserrat, sans-serif;
          }
          @media (max-width: 991px) {
            .div-4 {
              white-space: initial;
            }
          }
          .div-5 {
            margin: auto 0;
            font: 12px Montserrat, sans-serif;
            text-wrap: wrap;
          }
          .div-6 {
            display: flex;
            margin-top: 4px;
            gap: 4px;
            color: #fff;
            font-weight: 500;
            white-space: nowrap;
            width: calc(100% / 4);
          }
          @media (max-width: 991px) {
            .div-6 {
              white-space: initial;
            }
          }
          .div-7 {
            background-color: #494949;
            justify-content: center;
            padding: 6px 5px;
            font: 12px Montserrat, sans-serif;
          }
          @media (max-width: 991px) {
            .div-7 {
              white-space: initial;
            }
          }
          .div-8 {
            margin: auto 0;
            font: 12px Montserrat, sans-serif;
            text-wrap: wrap;
          }
          .div-10 {
            background-color: #d9d9d9;
            border-radius: 50%;
            width: 13px;
            height: 13px;
          }
          .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
          }

          /* Hide default HTML checkbox */
          .switch input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          /* The slider */
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }

          .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }

          input:checked + .slider {
            background-color: #27ad61;
          }

          input:focus + .slider {
            box-shadow: 0 0 1px #27ad61;
          }

          input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }

          /* Rounded sliders */
          .slider.round {
            border-radius: 34px;
          }

          .slider.round:before {
            border-radius: 50%;
          }
        `}</style>
      </div>
    </div>
  );
};

export default TokensS;
