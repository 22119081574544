import React, { useEffect, useContext } from 'react';
import AppRouter from "./components/AppRouter";
import { BrowserRouter } from "react-router-dom";

import { observer } from "mobx-react-lite";
import { Context } from "./index";
import { host } from './http';
import { jwtDecode } from 'jwt-decode';


const App = observer(() => {
  const { admin, exchanges } = useContext(Context);

  useEffect(() => { 
    exchanges.setExchanges(JSON.parse(localStorage.getItem("activeExchanges")));
  }, [exchanges])

  // useEffect(() => {
  //   const checkAuth = async () => {
  //     if (!document.cookie.includes('token=') || !document.cookie.includes('refreshToken=')) {
  //       admin.setIsAuth(false);
  //     } else {
  //       try {
  //         const refreshToken = document.cookie.split('; ').find(row => row.startsWith('refreshToken=')).split('=')[1];
  //         const response = await host.post("/user/api/jwt/refresh/", {refresh: refreshToken});
  //         const token = response.data.access;
  //         document.cookie = `token=${token}; path=/; secure; samesite=strict`;
  //         const decodedToken = jwtDecode(token);
  //         admin.setUser({ id: decodedToken.id });
  //         admin.setIsAuth(true);
  //       } catch (error) {
  //         document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //         document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //         admin.setIsAuth(false);
  //       }
  //     }
  //   };
  //   const intervalId = setInterval(checkAuth, 20000);
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [admin]);
  
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const referralCode = queryParams.get('r');
    if (referralCode) {
      localStorage.setItem('referralCode', referralCode);
    }
  }, []);

  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
})

export default App;